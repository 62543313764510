import { dbCollections } from "@/utils/firebaseCollection.js";
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()

export const addHistoryObjectToApplication = (object,id) => {
    return new Promise((resolve, reject) => {
        try {
            let docRef = db.collection(dbCollections.APPLICATIONS).doc(id).collection(dbCollections.HISTORY).doc()
            object.id = docRef.id
            docRef.set(object).then(()=>{
                resolve({status:true})
            }).catch((error)=>{
                console.error(error)
                reject({status:false,message:'Something went wrong.'})
            })
        } catch (error) {
            console.error(error)
            reject({status:false,message:'Something went wrong.'})
        }
    })
}